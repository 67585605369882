import { CommonState, API } from '@primeit/components-vue';
import moment from 'moment';
import { Config } from '@dav/security-component-spa';

const module = {
  actions: {
    FETCH_TO_METRICS_QUEUE: async ({ state, commit }) => {
      const config = await Config.getInstance();
      const companyId = config.get('dav-company-id');
      const apiName = 'RoomAPI';
      const path = '/sendtoaggregator';
      const user = CommonState.getters['credential/CURRENT_USER'];
      const endTime = moment();
      const duration = moment.duration(endTime.diff(state.entryTime)).asMinutes();

      const myInit = {
        body: {
          companyId: companyId,
          eventType: 'PERSON_LOGIN',
          personId: user.username,
          value: duration,
        },
        headers: {
          'x-dav-company-id': companyId,
        },
        response: true,
      };

      if (user) {
        myInit.headers['x-api-key'] = user.signInUserSession.accessToken.jwtToken;
      }

      try {
        await API.post(apiName, path, myInit);
      } catch (err) {
        console.error('error', err);
      }

      commit('RESET_VALUES');
    },
    SET_ENTRY_TIME({ commit }) {
      const startTime = moment();
      commit('SET_LOGIN_ENTRY_TIME', startTime);
    },
  },
  mutations: {
    RESET_VALUES(state) {
      state.entryTime = null;
      state.timeSpent = 0;
    },
    SET_LOGIN_ENTRY_TIME(state, time) {
      state.entryTime = time;
    }
  },
  namespaced: true,
  state: {
    entryTime: null,
    timeSpent: 0,
  },
  strict: true,
};


CommonState.registerModule('personLogin', module);
