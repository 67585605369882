import '../components/state/specialty.js';
import './appointment.js';
import './common.js';
import './company.js';
import './contract.js';
import './doctor.js';
import './domain.js';
import './emergency.js';
import './financial.js';
import './message.js';
import './patient.js';
import './schedule.js';
import './screening.js';
import './serviceRequest.js';
import './settings.js';
import './user.js';
import './personLogin.js';
//
