import './home/routes';
import './login/routes';
import './personschedule/routes.js';
import './appointment/routes';
import './doctoroffice/routes';
import './doctor/routes.js';
import './patient/routes.js';
import './emergency/routes.js';
import './messages/routes.js';
import './landingpage/routes';
import './requestreturnappointment/routes';
import './registerdependent/routes';
import './schedule/routes';
import './benefitclub/routes';
import './choosendependent/routes.js';


// esse cara tem que ser o ultimo
import './pageNotFound/routes.js';
