const ptBR = {
  btn: {
    accept: 'Aceito',
    accept_and_sign: 'Aceito e Cadastrar-me',
    access_appointment: 'Acessar Consulta',
    add: 'Adicionar',
    apply: 'Aplicar',
    archive: 'Arquivar mensagens',
    back: 'Voltar',
    cancel: 'Cancelar',
    cancel_payment: 'Cancelar pagamento',
    clear: 'Limpar',
    close: 'Fechar',
    close_room: 'Fechar Atendimento',
    confirm: 'Confirmar',
    confirm_payment: 'Confirmar Pagamento',
    confirmSchedule: 'Confirmar',
    copyToClipboard: 'Copiar o ID para a<br/>Área de Transferência',
    cycleVideo: 'Alterar Câmera',
    delete: 'Excluir',
    edit: 'Editar',
    emergency_entry: 'Entrar na fila',
    emergency_leave: 'Sair da Fila',
    enable_audio: 'Habilitar microfone',
    enable_keyboard: 'Habilitar teclado',
    entry: 'Entrar',
    filter: 'Filtrar',
    finish: 'Finalizar',
    finish_protocol: 'Finalizar Protocolo',
    forward: 'Encaminhar',
    free_attendance: 'Atendimento Grátis',
    get_out: 'Sair da fila',
    logout: 'Sair',
    network: 'Testar Internet',
    next: 'Próximo',
    no: 'Não',
    not_accept: 'Não Aceito',
    ok: 'Ok',
    pay: 'Pagar',
    payInLocal: 'Confirmar Pagamento no Local',
    prescriptionDigital: 'Prescrição digital',
    reactivate: 'Reativar',
    register: 'Cadastre-se',
    register_accept: 'Aceito e Cadastre-me',
    register_signin: 'Cadastrar',
    remove: 'Remover',
    repeat: 'Repetir',
    reply: 'Repetir',
    request: 'Solicitar',
    requestReturn: 'Solicitar retorno',
    resendMailMedic: 'Reenviar E-mail',
    resendMailPacient: 'Reenviar E-mail',
    save: 'Salvar',
    send: 'Enviar',
    sendFile: 'Enviar Arquivo',
    show_terms_use: 'Ver Termos de Uso',
    sign_up: 'Cadastrar-me',
    space: 'Espaço',
    speak: 'Falar',
    start: 'Iniciar',
    start_attendance: 'Iniciar Atendimento',
    type_here: 'Digite aqui...',
    unarchive: 'Desarquivar mensagens',
    video: 'Video',
    waitingroom: 'Sala de Espera',
    write: 'Escrever',
    yes: 'Sim',
  },
  btnTitles: {
    chat_close: 'Fechar Mensagens/Arquivos',
    chat_open: 'Abrir Mensagens/Arquivos',
    countTimeAttendance: 'Duração da Consulta',
    leave: 'Encerrar Chamada',
    mic_off: 'Ligar Microfone',
    mic_on: 'Desligar Microfone',
    more_options: 'Mais Opções',
    prescription: 'Prescrição',
    print: 'Enviar Foto da Tela',
    rec_on: 'Iniciar Gravação',
    rec_stop: 'Finalizar Gravação',
    send: 'Enviar Arquivo',
    share: 'Compartilhar Tela',
    video_off: 'Ligar Vídeo',
    video_on: 'Desligar Vídeo',
  },
  country: {
    AD: 'Andorra',
    AE: 'Emirados Árabes Unidos',
    AF: 'Afeganistão',
    AG: 'Antígua e Barbuda',
    AI: 'Anguila',
    AL: 'Albânia',
    AM: 'Armênia',
    AN: 'Antilhas Holandesas',
    AO: 'Angola',
    AQ: 'Antártica',
    AR: 'Argentina',
    AS: 'AmericanSamoa',
    AT: 'Áustria',
    AU: 'Austrália',
    AW: 'Aruba',
    AX: 'Ilhas Aland',
    AZ: 'Azerbaijão',
    BA: 'Bósnia-Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Bélgica',
    BF: 'Burkina Faso',
    BG: 'Bulgária',
    BH: 'Bahrein',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barthelemy',
    BM: 'Bermudas',
    BN: 'Brunei Darussalam',
    BO: 'Bolívia, Estado Plurinacional de',
    BR: 'Brasil',
    BS: 'Bahamas',
    BT: 'Butão',
    BW: 'Botsuana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canadá',
    CC: 'Ilhas Cocos (Keeling)',
    CD: 'Congo, República Democrática do Congo',
    CF: 'República Centro-Africana',
    CG: 'Congo',
    CH: 'Suíça',
    CI: 'Costa do Marfim',
    CK: 'Ilhas Cook',
    CL: 'Chile',
    CM: 'Camarões',
    CN: 'China',
    CO: 'Colômbia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cabo Verde',
    CX: 'Ilha de Natal',
    CY: 'Chipre',
    CZ: 'República Tcheca',
    DE: 'Alemanha',
    DJ: 'Djibouti',
    DK: 'Dinamarca',
    DM: 'Dominica',
    DO: 'República Dominicana',
    DZ: 'Argélia',
    EC: 'Equador',
    EE: 'Estônia',
    EG: 'Egito',
    ER: 'Eritréia',
    ES: 'Espanha',
    ET: 'Etiópia',
    FI: 'Finlândia',
    FJ: 'Fiji',
    FK: 'Falkland Islands (Malvinas)',
    FM: 'Micronésia, Estados Federados da Micronésia',
    FO: 'Ilhas Faroe',
    FR: 'França',
    GA: 'Gabão',
    GB: 'Reino Unido',
    GD: 'Grenada',
    GE: 'Geórgia',
    GF: 'Guiana Francesa',
    GG: 'Guernsey',
    GH: 'Gana',
    GI: 'Gibraltar',
    GL: 'Gronelândia',
    GM: 'Gâmbia',
    GN: 'Guiné',
    GP: 'Guadalupe',
    GQ: 'Guiné Equatorial',
    GR: 'Grécia',
    GS: 'Geórgia do Sul e as Ilhas Sandwich do Sul',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guiné-Bissau',
    GY: 'Guiana',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HR: 'Croácia',
    HT: 'Haiti',
    HU: 'Hungria',
    ID: 'Indonésia',
    IE: 'Irlanda',
    IL: 'Israel',
    IM: 'Ilha de Man',
    IN: 'Índia',
    IO: 'Território Britânico do Oceano Índico',
    IQ: 'Iraque',
    IR: 'Irã, República Islâmica do Golfo Pérsico',
    IS: 'Islândia',
    IT: 'Itália',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordânia',
    JP: 'Japão',
    KE: 'Quênia',
    KG: 'Quirguistão',
    KH: 'Camboja',
    KI: 'Kiribati',
    KM: 'Comores',
    KN: 'São Cristóvão e Névis',
    KP: 'Coréia, República Popular Democrática da Coréia',
    KR: 'Coréia, República da Coréia do Sul',
    KW: 'Kuwait',
    KY: 'Ilhas Cayman',
    KZ: 'Cazaquistão',
    LA: 'Laos',
    LB: 'Líbano',
    LC: 'Santa Lúcia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Libéria',
    LS: 'Lesotho',
    LT: 'Lituânia',
    LU: 'Luxemburgo',
    LV: 'Letônia',
    LY: 'Jamahiriya Árabe Líbia',
    MA: 'Marrocos',
    MC: 'Mônaco',
    MD: 'Moldávia',
    ME: 'Montenegro',
    MF: 'São Martinho',
    MG: 'Madagascar',
    MH: 'Ilhas Marshall',
    MK: 'Macedônia',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongólia',
    MO: 'Macau',
    MP: 'Ilhas Marianas do Norte',
    MQ: 'Martinica',
    MR: 'Mauritânia',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldivas',
    MW: 'Malauí',
    MX: 'México',
    MY: 'Malásia',
    MZ: 'Moçambique',
    NA: 'Namíbia',
    NC: 'Nova Caledônia',
    NE: 'Níger',
    NF: 'Ilha Norfolk',
    NG: 'Nigéria',
    NI: 'Nicarágua',
    NL: 'Países Baixos',
    NO: 'Noruega',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nova Zelândia',
    OM: 'Omã',
    PA: 'Panamá',
    PE: 'Peru',
    PF: 'Polinésia Francesa',
    PG: 'Papua Nova Guiné',
    PH: 'Filipinas',
    PK: 'Paquistão',
    PL: 'Polônia',
    PM: 'São Pedro e Miquelon',
    PN: 'Pitcairn',
    PR: 'Porto Rico',
    PS: 'Território Palestino, Ocupado',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguai',
    QA: 'Qatar',
    RE: 'Reunião',
    RO: 'Romênia',
    RS: 'Sérvia',
    RU: 'Rússia',
    RW: 'Ruanda',
    SA: 'Arábia Saudita',
    SB: 'Ilhas Salomão',
    SC: 'Seychelles',
    SD: 'Sudão',
    SE: 'Suécia',
    SG: 'Cingapura',
    SH: 'Santa Helena, Ascensão e Tristão da Cunha',
    SI: 'Eslovênia',
    SJ: 'Svalbard e Jan Mayen',
    SK: 'Eslováquia',
    SL: 'Serra Leoa',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somália',
    SR: 'Suriname',
    SS: 'Sul do Sudão',
    ST: 'São Tomé e Príncipe',
    SV: 'El Salvador',
    SY: 'República Árabe da Síria',
    SZ: 'Swazilândia',
    TC: 'Ilhas Turcas e Caicos',
    TD: 'Chade',
    TG: 'Togo',
    TH: 'Tailândia',
    TJ: 'Tajiquistão',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turcomenistão',
    TN: 'Tunísia',
    TO: 'Tonga',
    TR: 'Turquia',
    TT: 'Trinidad e Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzânia, República Unida da Tanzânia',
    UA: 'Ucrânia',
    UG: 'Uganda',
    US: 'Estados Unidos',
    UY: 'Uruguai',
    UZ: 'Uzbequistão',
    VA: 'Santa Sé (Estado da Cidade do Vaticano)',
    VC: 'São Vicente e as Granadinas',
    VE: 'Venezuela, República Bolivariana da Venezuela',
    VG: 'Ilhas Virgens - Britânicas',
    VI: 'Ilhas Virgens - Estados Unidos',
    VN: 'Vietnã',
    VU: 'Vanuatu',
    WF: 'Wallis e Futuna',
    WS: 'Samoa',
    YE: 'Iêmen',
    YT: 'Mayotte',
    ZA: 'África do Sul',
    ZM: 'Zâmbia',
    ZW: 'Zimbábue',
  },
  label: {
    actions: 'Ações',
    additional_minute_recorded_allowed: 'Permite Adicional',
    additional_minute_value: 'Valor Adicional',
    additional_minutes_allowed: 'Permite Adicional',
    additional_recorded_minute_value: 'Valor Adicional',
    address: 'Endereço',
    adress: 'Endereço',
    any_appointments: 'Não há agendamentos',
    appointment_duration: 'Tempo em minutos de duração da consulta padrão, utilizado para agendamento',
    appointment_reason: 'PA Agendado',
    attendance: 'Agendamento',
    attendance_average_minutes: 'Média Minutos Apuração',
    attendance_average_minutes_recorded: 'Média Minutos Gravação',
    attendance_average_minutos: 'Frequência média minutos',
    attendance_average_minutos_recorded: 'Frequência média minutos gravados',
    attendance_value: 'Valor Atendimento',
    attention: 'Atenção!',
    audio: 'Audio',
    available_forwarding: 'Encaminhamentos disponíveis',
    available_specialty: 'Especialidades disponíveis',
    bill_type: 'Tipo',
    billing_data: 'Dados da cobrança',
    bio: 'Bio',
    birth_date: ' Data de Nascimento',
    business_contact: 'Contato de negócio',
    by_period: 'Por período',
    camera: 'Câmera',
    cancel_reason: 'Motivo de Cancelamento',
    cancel_time: 'Data / Hora do Cancelamento',
    cell_phone: 'Celular',
    change_password: 'Alteração de senha',
    chat: 'Chat',
    cid10: 'CID10',
    city: 'Cidade',
    click_here: 'Clique aqui',
    close: 'Fechar',
    cnpj: 'CNPJ',
    comission_type: 'Tipo de comissão',
    comission_value: 'Valor de comissão',
    comments: 'Observações',
    commission: 'Comissão',
    company_id: 'Empresa',
    complaint: 'Reclamação',
    complement: 'Complemento',
    confirm: 'Confirmar',
    connection_lost: 'Conexão Perdida',
    connections: 'Detalhes da consulta',
    consultation_detail: 'Detalhes da consulta',
    consultation_link: 'Link da consulta',
    consultation_time: 'Tempo total de duração da consulta',
    consultation_time_doctor: 'Tempo total do profissional na sala de atendimento',
    consultation_time_patient: 'Tempo total do paciente na sala de atendimento',
    contact: 'Número de contato',
    country_contact: 'Código do país',
    countryname: 'País',
    cpf: 'CPF',
    credit_card: 'Cartão de Crédito',
    credit_card_security_code: 'CVV',
    crm: 'CRM',
    crmState: 'UF do CRM',
    DadosPessoais: 'Dados Pessoais',
    dataConfirm: 'Confirmar os dados',
    date: 'Data',
    date_valid_credit_card: 'Validade do Cartão de Crédito',
    day: 'Dia',
    days: 'Dias',
    description: 'Descrição',
    discount: 'Desconto',
    doctor: 'Profissional',
    doctor_id: 'Profissional',
    dont_show_informations: 'Não quero mais ver essa informação',
    duration: 'Duração',
    duration_days: 'Dias de Duração',
    edit: 'Editar',
    edit_address: 'Editar endereço',
    edit_data: 'Editar dados',
    elective: 'Eletivo',
    emergency: 'PA Agendado',
    emergency_attended_now: 'Você será atendido agora por <b>{0}</b>!',
    emergency_attended_now_without_doctor_name: 'Você será atendido agora!',
    emergency_attended_now_without_doctor_name_inclusive: 'Você será atendido(a) agora!',
    emergency_attended_redirect_seconds: 'Você será redirecionado em:',
    emergency_attended_redirect_seconds_inclusive: 'Você será redirecionado(a) em:',
    emergency_attended_seconds:
      'Em {0} segundos você será redirecionado(a) para a sala de atendimento. Se preferir, clique no botão abaixo:',
    emergency_back_home: 'Página Inicial',
    emergency_complaint: 'Motivo da Consulta',
    emergency_dashboard_seconds:
      'Em {0} segundos você será redirecionado(a) para o painel. Se preferir, clique no botão abaixo:',
    emergency_finished: 'Seu atendimento foi finalizado',
    emergency_finished_by_manager: 'Seu protocolo foi finalizado',
    emergency_init_btn: 'NOVO ATENDIMENTO',
    emergency_init_new: 'Caso queira iniciar um novo atendimento',
    emergency_is_offline: 'O pronto atendimento está desligado!',
    emergency_line_position: 'Você está em <strong> {0}º </strong> lugar na fila de <strong> {1} </strong>',
    emergency_professional_attended_now: 'Você iniciará seu atendimento agora!',
    emergency_return: 'Se você quiser retornar',
    emergency_return_btn: 'Retornar ao Atendimento',
    emergency_return_click_btn: 'por favor pressione o botão <strong> {0} </strong> abaixo.',
    emergency_service: 'Pronto-Atendimento',
    emergency_start_attendance: 'Iniciar Atendimento',
    emergency_still_active: 'Seu atendimento emergencial ainda está ativo',
    emergency_wait: 'Por favor aguarde...',
    emergency_wait_line: 'Em mais alguns instantes você será atendido(a)...',
    end_and_accomplish: 'Sair e Finalizar',
    end_appointment_question: 'Deseja sair e finalizar o atendimento?',
    end_date: 'Data Término',
    end_date_time: 'Data / Hora Término',
    end_out: 'Somente sair',
    end_time: 'Hora Término',
    end_time_waiting_room_attendance: 'A hora marcada do atendimento já passou em:',
    endereco: 'Endereço',
    enter_service: 'Entrar no atendimento',
    entered: 'Entrou',
    error_prescription: 'Prescrição Indisponível',
    expiration_date: 'Data de Validade',
    field_required: '* Campo Obrigatório',
    file: 'Arquivos',
    forward: 'Encaminhar',
    forward_patient: 'Selecione a fila e preencha as notas do atendimento para encaminhar o paciente',
    foto: 'Foto',
    gender: 'Sexo',
    goodAfternoon: 'Boa tarde',
    goodEvening: 'Boa noite',
    goodMorning: 'Bom dia',
    groups: 'Filas',
    hello: 'Olá,',
    hello_header_emergency_professional: 'Bem vindo(a) ao checkin do Pronto Atendimento!',
    helloHeader: 'Olá',
    history: 'Histórico',
    history_item_type_APPOINTMENT: 'Atendimento',
    history_item_type_ARCHIVE: 'Arquivo',
    history_item_type_DIAGNOSTIC: 'Exame',
    history_item_type_EVENT: 'Evento',
    history_item_type_EXAM: 'Exame',
    history_item_type_LINK: 'Link',
    history_item_type_MEDICAL_REFERRAL: 'Encaminhamento médico',
    history_item_type_NOTES: 'Notas',
    history_item_type_PRESCRIPTION: 'Prescrição Digital',
    history_item_type_QUESTIONNAIRE: 'Solicitação',
    history_type_event: 'Tipo do Evento:',
    hour: 'Horas',
    hours: 'Horas',
    id: 'id',
    initial_attendance_quantity: 'Quantidade inicial Atendimento',
    installment: 'Parcelamento',
    is_landing: 'Disponível no site ?',
    item: 'Item',
    landing_page: 'Página inicial',
    left: 'Saiu',
    license_council: 'Conselho',
    limit: 'Limite',
    link: 'Link',
    load_more: 'Carregar mais ...',
    mail: 'E-mail',
    make_your_registration: 'Faça seu cadastro',
    max_additional_minute: 'Máximo Adicionais',
    max_additional_recorded_minute: 'Máximo Adicionais',
    max_attendance_minutes: 'Minutos máximos de participação',
    medical_assistent: 'Profissional Assistente',
    medical_specialty: 'Especialidade',
    medicines: 'Medicação',
    message: 'Mensagem',
    messages: 'Mensagens',
    messages_history: 'Histórico de Mensagens',
    mic: 'Microfone',
    minutes: 'Minutos',
    minutes_consultation: 'Atendimento Minutos',
    minutes_quantity: 'Quantidade de minutos',
    minutos_value: 'Valor de minutos',
    months: 'mês',
    more_details: 'Mais Detalhes',
    my_informations: 'Minhas Informações',
    name: 'Nome',
    name_card: 'Nome completo(Igual ao escrito no cartão)',
    neighborhood: 'Bairro',
    newAddress: 'Salvar como endereço padrão',
    next_appointments: 'Próximas Consultas',
    no_data_available: 'Não há dados disponíveis',
    no_data_medical_for_patient: 'Não há registros médicos disponíveis <br/> para este paciente',
    notes: 'Notas',
    notes_appointment: 'Notas sobre o atendimento',
    notes_optional: 'Notas (opcional)',
    now: 'AGORA',
    nps_finish_seconds:
      'Em {0} segundos você será redirecionado(a) para a página inicial. Se preferir, clique no botão abaixo:',
    number: 'Número',
    number_card: 'Número do Cartão de Crédito',
    number_of_installments: 'Número de parcelas',
    observation: 'Observação',
    one_file: 'Arquivo(s)',
    orientation: 'Orientações ao Paciente',
    other: 'OUTROS',
    other_informations: 'Outras informações',
    other_participant: 'Outros Participantes',
    out: 'Sair',
    out_appointment_question: 'Deseja sair do atendimento?',
    parent: 'Empresa Pai',
    parent_access_clinical_data: 'Compartilhar dados clínicos com o responsável?',
    parent_id: 'Responsável',
    participants: 'Participantes',
    patient: 'Paciente',
    patient_data: 'Dados do Paciente',
    patient_document: 'Documento: {0}',
    patient_id: 'Paciente(s)',
    patient_name: 'Nome: {0}',
    payment: 'Pagamento',
    payment_confirmed: 'Pagamento Confirmado',
    payment_confirmed_appreciate: 'Agradecemos pela sua confiança e preferência',
    payment_method: 'Método de Pagamento',
    period: 'Período',
    permalink: 'Link personalizado',
    personaldata: 'Dados Pessoais',
    pix: 'PIX',
    place: 'Lugar',
    place_text: 'Digite o texto aqui...',
    plan: 'Plano',
    plan_id: 'ID do plano',
    plandata: 'Dados do Plano',
    please_fill_address: 'Preencha os dados de endereço do paciente',
    please_register: 'Preencha seus dados e faça seu cadastro',
    powered_by_dav: 'Desenvolvido com ❤ pelo Doutor ao Vivo',
    praise: 'Elogio',
    pre_evaluation: 'Pré-Avaliação',
    prescription_data: 'Medicamentos Prescritos',
    prescription_description_generate: 'Prescrição via {0}',
    prescription_include_address_field: 'Endereço na precrição',
    prescription_settings: 'documentos médicos',
    prescription_virtual_include_address: 'Incluir endereço em prescrição digital?',
    profession: 'Profissão',
    professional_id: 'Profissional',
    professional_registration: 'Registro Profissional',
    protocol: 'Protocolo',
    quantity: 'Quantidade',
    ranges: 'Faixas',
    rating: 'Avaliação',
    reason: 'Queixa Principal',
    reconnect: 'Clique aqui para se reconectar',
    record_start: 'Iniciado a gravação de audio e video dos participantes...',
    record_stop: 'Parando a gravação de audio e video dos participantes...',
    recording_consultation: 'Gravação Atendimento',
    recordings: 'Gravações',
    region: 'UF',
    register_dependent: 'Cadastrar Dependente',
    registration: 'Registro',
    registration_state: 'UF do registro',
    remetent: 'Remetente',
    representative: 'Representante',
    representative_id: 'ID representante',
    result_description: 'Resultado',
    resume: 'Resumo da Compra',
    role: 'Cargo',
    room_active: 'Você está na sala de espera para sua Teleconsulta.',
    satisfaction_form_title_message: 'Obrigado por participar e responder nosso formulário de satisfação!',
    save_address_default: 'Salvar essas informações como padrão',
    save_note_appointment: 'Salvando Notas sobre o atendimento...',
    save_orientation_appointment: 'Salvando Orientações ao Paciente ...',
    save_reason_appointment: 'Salvando Queixa Principal ...',
    saving: 'Salvando...',
    scheduler: 'Agendar',
    schedules: 'Horários',
    search: 'Pesquisar',
    seconds: 'Segundos',
    select: 'Selecionar',
    select_patient: 'Selecione o paciente',
    select_the_professional: 'Selecione o profissional',
    select_the_specialty: 'Selecione a especialidade',
    select_user: 'Selecione um usuário',
    service_queue: 'Fila de atendimento',
    services: 'Serviços',
    size: 'Tamanho',
    social_name: 'Nome Social',
    source: 'Canal',
    source_options: {
      clinic: 'Clínica',
      emergency: 'Fila virtual',
      patient: 'Auto agendamento',
    },
    specialty: 'Especialidade',
    start_attendance: 'A Teleconsulta vai iniciar em:',
    start_date: 'Data Início',
    start_date_time: 'Data / Hora Início',
    start_time: 'Hora Início',
    status: 'Status',
    status_appointment: 'Status Agendamento',
    street: 'Logradouro',
    suggestion: 'Sugestão',
    telephone: 'Telefone',
    term_of_use_accept: 'Aceite de Termos de Uso',
    term_use_agree_with: 'Li e concordo com ',
    text_term_use_accept: 'Li e concordo com <a href="{0}" target="_blank" rel="noreferrer nofollow">{1}</a>',
    text_term_use_login:
      'A {0} atualizou os <b rel="noreferrer nofollow">Termos de Uso</b>. Estamos empenhados em proteger a privacidade e segurança dos nossos usuarios.',
    time_course: 'Período',
    timeToSchedule: 'Horário',
    timezone: 'Fuso Horário',
    title: 'Título',
    total: 'Total',
    try_again_later: 'Por favor, tente novamente mais tarde!',
    type: 'Tipo',
    uhc_id: 'Cartão Nacional de Saúde',
    upshot: 'Upshot',
    use_voucher: 'Possui voucher?',
    use_voucher_value: 'Digite o código do voucher',
    value: 'Valor',
    value_attendance: 'Valor do Atendimento',
    value_discount: 'Valor de Desconto',
    value_paid: 'Valor Total',
    video: 'Vídeo',
    view_messages: 'Visualizar Mensagens',
    wait_other_participant:
      'Por favor, aguarde outro participante entrar na sala para os recursos de áudio e vídeo serem liberados',
    wait_room_subtitle: 'Você está na sala de espera para sua Teleconsulta',
    wait_room_subtitle2: 'Você está na sala de espera para sua Teleconsulta de {0}',
    warning: 'Aviso',
    welcome_home: 'Bem-vindo(a) ao consultório da',
    years: 'ano(s)',
    zip_code: 'CEP',
  },
  message: {
    access_denied: 'Acesso Negado',
    after_register_dependent:
      'Para poder visualizar ou logar com seu dependente cadastrado, por favor clique no botão sair e faça login novamente.',
    alert_queue_finsh: 'Você está saindo da fila, deseja realmente continuar com essa ação?',
    appointmentDescription: 'Atendimento de {0} paciente {1} e Profissional {2} em {3}',
    appointmentTitleDate: 'em {0}',
    appointmentTitlePAT: 'Atendimento agendado de {0}, paciente {2} e Profissional {1}',
    attendance_accomplish: 'Atendimento Finalizado pelo Profissional',
    attendance_finished: 'O atendimento com o paciente foi finalizado',
    attendance_needs_payment: 'Para ter acesso ao teleatendimento, primeiro realize o pagamento.',
    attendance_save_failure: 'Unable to save your schedule, please try again',
    billing_data_message: 'Preencha os dados de cobrança clicando no botão de editar logo acima',
    browser_deny_camera:
      'Sua câmera e/ou microfone estão bloqueados.<br/> Precisamos desse acesso para o Teleatendimento. Clique no ícone de câmera bloqueada <span class="material-icons" style="vertical-align: bottom;color: #000;">videocam_off</span> na barra de endereço do seu navegador e atualize a página',
    browser_not_suport: 'Esse Browser não suporta a sala de atendimento, favor acessar de outro browser.',
    cancel_the_service: 'Tem certeza que deseja cancelar o atendimento?',
    change_user: 'Trocar usuário',
    chat_checkin: '{0} entrou',
    chat_checkout: '{0} saiu',
    choice_dependent: 'Selecione o paciente que será atendido',
    choose_one_of_two_options: 'Escolha uma das duas opções abaixo:',
    choose_option_attendance:
      'Escolha o próximo passo. Caso não seja tomada nenhuma ação o protocolo sera finalizado em {0}',
    chooseOption: 'Escolha a opção que deseja realizar',
    confirm_exit_alert: 'Tem certeza de que deseja sair? Seus dados não serão salvos.',
    confirm_sign_out: 'Realmente deseja sair?',
    connectionFail:
      'Não será possível entrar na sala de atendimento, favor verificar se o agendamento ainda está válido.',
    copySuccess: 'Copiado com sucesso',
    cupom_valid: 'Desconto aplicado com sucesso',
    dialog_pix_code: 'Identificando sua transferência com um identificador único',
    dialog_pix_confirmation_button: 'Aperte no botão <strong>Confirmar Pagamento</strong> para fechar essa janela',
    dialog_pix_copy_01: 'Copie o ID da transação',
    dialog_pix_copy_02: 'No app do seu banco, vá para área Pix',
    dialog_pix_copy_03: 'Escolha a opção <strong>Pix Copia e Cola</strong>',
    dialog_pix_copy_04: 'Cole o código que foi copiado',
    dialog_pix_finish_transaction: 'Confirme as informações e finalize a transação',
    dialog_pix_instructions_header: 'Instruções de pagamento com ID',
    dialog_pix_instructions_header_qr_code: 'Instruções de pagamento com QR Code',
    dialog_pix_qr_code_01: 'No aplicativo do seu banco, vá até a área Pix',
    dialog_pix_qr_code_02: 'Clique em <strong>Pagar</strong> e selecione a opção <strong>Ler QR Code</strong>',
    dialog_pix_qr_code_03: 'Aponte a câmera do celular para a QR Code',
    dialog_pix_your_payment_expires: 'Seu pagamento PIX expira em',
    dialog_prescription_error: 'Para prosseguir com a prescrição cadastre seu endereço no seu perfil de profissional',
    dialog_prescription_error_international_address:
      'Não é permitido endereço internacional para gerar uma prescrição. Certifique que o seu endereço está cadastrado no Brasil',
    dialogCancelAppointmentPayment: 'Você realmente quer cancelar o pagamento?',
    dialogCancelRangeTitle: 'Confirme o cancelamento',
    dialogCancelSelectText: 'Tem certeza de que deseja cancelar?',
    dialogDeleteRangeText: 'Tem certeza de que deseja excluir esta faixa?',
    dialogDeleteRangeTitle: 'Confirme a exclusão',
    dialogDeleteSelectText: 'Tem certeza de que deseja excluir?',
    entered_another_location: 'Prezado(a) você foi desconectado porquê entrou novamente em outro local.',
    error_kioski_navigation: 'Erro de Navegação. Feche o browser ou clique no botão fechar.',
    field_is_required: '{0} é obrigatório.',
    file_added_successfully: 'Arquivo adicionado com sucesso',
    file_already_exists: 'Este arquivo já foi adicionado à consulta, favor verificar a lista e tentar novamente',
    file_delete_successfully: 'Arquivo excluído com sucesso',
    file_limited_size: 'O tamanho limite de cada arquivo para envio é de {0}MB',
    file_send_timeout: 'Não foi possível enviar o arquivo, favor tentar novamente',
    fill_in_and_click_next: 'Preencha as informações e clique em "<strong>Próximo</strong>".',
    formErrorText: 'Dados inconsistentes.',
    formErrorTitle: 'Erro',
    formSuccessText: 'Foi realizado com sucesso.',
    going_to_ask: 'Agora, vou te fazer algumas perguntas',
    inactive_room:
      'Prezado(a) a consulta parece estar inativa por muito tempo, clique no botão a baixo para continuar na consulta, ou a consulta será fechada em {0} segundos',
    invalidDate: 'Data de nascimento inválida.',
    invalidMail: 'Deve ser um e-mail válido.',
    isRequired: '{0} é obrigatório.',
    isUnique: '{0} já existente',
    isValid: '{0} é inválido.',
    kioski_finish: 'Obrigado por usar nosso serviço',
    loading: 'Carregando',
    make_the_payment: 'Clique aqui para efetuar o pagamento',
    maxLength: '{0} deve ter no máximo {1} caracteres.',
    maxLength_observation: '{0} deve ter no máximo {1} caracteres.',
    minLength: '{0} deve ter pelo menos {1} caracteres.',
    my_availability: 'Minha Disponibilidade',
    my_profile: 'Meu Perfil',
    new_checkin_participant: '{0} entrou na sala de atendimento',
    new_checkout_participant: '{0} saiu da sala de atendimento',
    new_msg_participant: '{0} enviou uma nova mensagem no chat',
    no: 'Não',
    no_access_benefits_club: 'Você não tem acesso ao clube de benefícios. Contate o administrador da clínica.',
    no_participants: 'Nenhum participante está no video principal, favor escolher o participante do video principal.',
    no_patient: 'Não há paciente regitrado na consulta.',
    noData: 'Sem dados disponíveis',
    paid_attendance_success:
      'O pagamento da consulta foi realizado com sucesso! Enviamos um e-mail com informações sobre o acesso à sala de atendimento',
    paid_inlocal: 'O pagamento deverá ser efetuado no local',
    participant_data_problem: 'Não foi possível gerar a prescrição devido à problemas com os dados dos participantes:',
    payment_failure: 'Pagamento não foi aceito, verifique se os dados informados estão corretos',
    payment_in_analysis: 'Pagamento em análise. Notificaremos quando for aprovado',
    payment_not_confirmed:
      'Pagamento não encontrado. Por favor, certifique que a transação com seu banco ocorreu com sucesso',
    payment_success: 'Pagamento Realizado com sucesso',
    pix_instructions_header: 'Instruções',
    pix_instructions_line_01:
      'Se organize: <b>você terá 15 minutos</b> para efetuar a transação junto ao App. Uma vez expirado, você vai precisar cancelar a transação e refazer a operação.',
    pix_instructions_line_02:
      'Quando estiver pronto, aperte o botão para iniciar a transação. Será gerado o PixCode e o QR Code',
    pix_instructions_line_03: 'Copie e cole o código OU escaneie o QR Code usando sua aplicação bancária.',
    pix_instructions_line_04: 'Clique em confirmar transação para verificar se o pagamento foi correto.',
    pix_instructions_line_05: 'Bom atendimento.',
    prescription_error: 'Erro ao abrir prescrição. Por favor, tente novamente.',
    registration_unallowed: 'O cadastro de pacientes não é autorizado pela clínica',
    request_appointment: 'Solicitação de Agendamento',
    request_body:
      'Olá, estou entrando em contato para solicitar um agendamento com o especialista de {0}.<br/> Meu contato: {1} {2} <br/> Observação: {3}',
    request_return_appointment: 'Solicitação de Retorno',
    request_return_body:
      'Olá, estou entrando em contato para solicitar um agendamento de retorno. <br/> <br/> <strong>Dados da primeira consulta:</strong> <br/> <strong>Data: </strong> <span>{0}</span> <br/> <strong>Profissional: </strong> <span>{1}</span> <br/> <strong>Especialidade: </strong> <span>{2}</span> <br/> <br/> <strong>Meu contato: </strong> <span>{3} {4}</span> <br/> <strong>Observação: </strong> <span>{5}</span>',
    required_billing_data_message: 'Por favor, preencha os dados de cobrança',
    required_fields: 'Por favor, certifique-se de que preencheu todos os campos obrigatórios',
    response_automatic:
      'Olá, nosso time irá verificar a disponibilidade e vai te retornar com opções de datas. <br/> Após o retorno das datas, escolha o melhor dia e horário e realize o pagamento da consulta. <br/> Com o pagamento confirmado você receberá o acesso para o dia da consulta.',
    response_return_automatic:
      'Olá, nosso time irá verificar a disponibilidade e vai te retornar com opções de datas. <br/> Após o retorno das datas, escolha o melhor dia e horário. <br/> Após nossa equipe agendar o seu retorno você receberá o acesso para o dia da consulta.',
    room_accomplish: 'O seu atendimento foi finalizado',
    room_end: 'Você saiu do seu atendimento',
    saving_prescription: 'Estamos salvando essa prescrição nos arquivos da sala... Aguarde.',
    service_guideline:
      'Este atendimento possui as diretrizes abaixo. Para acessá-las durante a consulta, clique no ícone de estrela no menu da sala de atendimento.',
    sign_out: 'Sair',
    start_screening: 'Clique em "Iniciar" para começar a triagem',
    termPendingAccept: 'Termos de uso do sistema pendente de leitura e de aceite.',
    voucher_not_apply: 'Voucher não aplicado',
    wiping_prescription: 'Estamos excluindo essa prescrição... Aguarde.',
    without_audio_video: 'Câmera e microfone não foram habilitados.',
    yes: 'Sim',
  },
  pages: {
    appointment: {
      detail: 'Detalhes da consulta',
      edit: 'Editar Agendamento',
      list: 'Agendamentos',
      list_age: 'Agenda',
      list_all: 'Minhas Consultas',
      list_today: 'Agenda',
      new: 'Novo Agendamento',
    },
    benefit_club: 'Clube de benefícios',
    cancel: {
      attendance_confirm: 'Seu atendimento{0} está sendo confirmado para o dia {1}, e os participantes serão:',
      confirm_title: 'Confirmar cancelamento',
      invalid:
        'O atendimento não pode ser cancelado no momento. Se necessário, entre em contato com a empresa para realizar o cancelamento através de outros canais.',
      is_payment:
        'Se você deseja cancelar este agendamento por favor entre em contato com a empresa para realizar o cancelamento do agendamento.',
      payment_free: 'Se você deseja cancelar este agendamento por favor clique no botão abaixo',
      reason: 'Motivo do cancelamento',
      reason_describe: 'Descreva o motivo do cancelamento',
      success_text: 'Seu atendimento{0} para o dia {1} está cancelado. Os participantes seriam:',
      unable: 'Cancelamento indisponível',
    },
    choiceDependent: {
      title: 'Escolher paciente'
    },
    company: {
      Detail: 'Detalhes',
      Edit: 'Editar',
      List: 'Empresas',
      New: 'Nova Empresa',
    },
    dependent: {
      title: 'Registrar Dependente',
    },
    doctor: {
      edit: 'Editar Perfil',
    },
    doctoroffice: {
      ehr: 'Prontuário Eletrônico',
      end: 'Término do Atendimento.',
      payment: 'Pagamento',
      room: 'Consultório',
    },
    drawner: {
      change_user: 'Trocar usuário',
      my_profile: 'Meu perfil',
      register_dependent: 'Cadastrar Dependente',
    },
    emergency: {
      arrivalInfo:
        'Realize sua consulta de forma simples e rápida Selecione uma <b class="font-weight-bold">fila</b> e clique em <b class="font-weight-bold">próximo</b>.',
      arrivalTitle: 'Filas disponíveis',
      attendance_now: 'Atendimento Agora',
      check_resources_confirm:
        'Após realizar o teste da câmera e ler as recomendações, clique em <b class="font-weight-bold">próximo</b> para acessar a fila',
      check_resources_recommend: {
        battery: 'Certifique-se que seu celular está com carga suficiente',
        headphones: 'Se possível, use fones de ouvido',
        light: 'Certifique-se de que a sua iluminação está boa',
        noise: 'Evite lugares agitados e com muito barulho',
        polite: 'Procure ser educado com os profissionais que irão te atender',
        video: 'Verifique se os seus recursos de audio e vídeo estão habilitados',
        wifi: 'De preferência esteja conectado a uma rede WiFi',
      },
      dashboard: {
        actions: 'Ação',
        groups: 'Filas',
        info: {
          document: 'Registro / CPF',
          patient: 'Paciente',
          position: 'Posição',
          telephone: 'Telefone',
        },
        patients: 'Pacientes',
      },
      emergency: 'Fila Virtual',
      emergency_greeting:
        'Abaixo você pode escrever o motivo da consulta e adicionar exames ou arquivos. Em seguida, clique no botão "PRÓXIMO"',
      emergency_reason: 'Descreva brevemente o motivo da consulta',
      emergency_start:
        'Seu atendimento está sendo iniciado, aguarde o tempo estimado ou clique em <b class="font-weight-bold">Iniciar atendimento </b>.',
      emergencyWelcome: 'Bem vindo(a) à fila virtual',
      fill_nps: 'NPS',
      flow_group: 'Fila',
      flow_steps: {
        attendance: 'Acesso ao atendimento',
        collection_of_vital_signs: 'Coleta dos sinais vitais',
        orientations: 'Orientações',
        payment: 'Pagamento',
        rank: 'Fila virtual',
        reason: 'Motivo',
        resources: 'Teste de Câmera',
        start_button_scouting: 'Clique no botão <strong>"Iniciar"</strong> para começar a aferição.',
      },
      general: 'Atendimento Geral',
      next: 'Próximo',
      next_attendance: 'Próximo atendimento',
      no_professional_emergency_groups: 'Nenhuma fila para fazer checkin, verifique seu acesso com o Gestor.',
      orientation_steps: {
        attendance: 'Acesse seu atendimento.',
        orientation_title: 'Orientações para realizar o acesso a Fila Virtual',
        orientations: 'Para iniciar o acesso, clique em próximo.',
        orientations_group: 'Selecione uma fila de atendimento.',
        payment: 'Realize o pagamento do seu atendimento.',
        rank: 'Acesse a Fila Virtual e espere pela sua vez de ser atendido(a).',
        reason: 'Descreva brevemente o motivo do seu atendimento e anexe exames, se houver.',
        resources: 'Teste os seus recursos de áudio e vídeo.',
      },
      patient_dispensing: 'Dispensa de paciente',
      person_post_attendance_subtitle:
        'Por favor, aguarde a ação do profissional. Em instantes você será direcionado ao próximo passo',
      person_post_attendance_title: 'Seu atendimento está sendo finalizado...',
      post_attendance: 'Pós Atendimento',
      professional_emergency_checkin: 'Clique no botão abaixo para acessar a fila com a especialidade {0}',
      professional_emergency_greeting: 'Bem vindo(a) ao checkin do Pronto Atendimento',
      professional_emergency_groups: 'Selecione as filas que deseja acessar',
      protocol_finished: 'Seu protocolo foi finalizado pelo gestor, qualquer duvida entre em contato com a empresa.',
      reason_finished_label: 'Motivo: {0}',
      start_emergency: 'Para iniciar o acesso clique em próximo.',
      start_emergency_group: 'Para iniciar o acesso, selecione uma fila de atendimento e clique em próximo.',
      user_protocol: 'O seu protocolo é <strong> {0} </strong>',
      virtual_line: 'Fila Virtual',
    },
    home: 'Página Inicial',
    ladingpage: {
      home: 'Paginal Inicial',
      list_professionals: 'Nossos Especialistas',
      professional_profile: 'Perfil do Professional',
    },
    login_page: {
      back: 'Voltar',
      birthdate_is_required: 'Preencha a data de nascimento.',
      close: 'Fechar',
      confirm: 'Confirmação',
      continue: 'Continuar',
      dont_have_account: 'Ainda não possui uma conta?',
      empty_mail: 'O email não pode ser vazio',
      enter_your_data: 'Informe seus dados',
      forgot_password: 'Esqueci minha senha',
      forgot_password_message: 'Enviaremos um link de recuperação para o seu usuário',
      forgot_your_password: 'Esqueceu sua senha?',
      invalid_birthdate: 'Data de nascimento inválida.',
      invalid_cpf: 'CPF inválido.',
      invalid_date_of_birth: 'Data de nascimento inválida.',
      invalid_email: 'E-mail inválido.',
      invalid_password: 'Senha inválida',
      invalid_universal_healthcare_id: 'Cartão nacional de saúde inválido.',
      log_in: 'Entrar',
      login_to_your_account: 'Entre na sua conta',
      message_forgot_mail: 'Nova senha enviada para o email informado',
      message_forgot_mail_error: 'Não foi possivel enviar uma nova senha para o email informado',
      new_password: 'Nova Senha',
      password: 'Senha',
      password_confirm: 'Confirmação deve ser igual a senha escolhida',
      password_min_length: 'A senha deve possuir pelo menos 8 caracteres',
      required_cpf: 'CPF é obrigatório.',
      send_mail: 'Enviar email',
      type_your_birthdate: 'Digite sua data de nascimento',
      type_your_cpf: 'Digite seu CPF',
      type_your_email: 'Digite seu email',
      type_your_password: 'Digite sua senha',
      type_your_universal_healthcare_id: 'Digite seu Cartão Nacional de Saúde',
      user_not_found: 'Usuário não encontrado',
      valid_password:
        'A senha deve possuir pelo menos 8 caracteres e ser composta por letras maiúsculas, minúsculas e números',
    },
    message: {
      archive: 'Arquivado',
      archivedSuccess: 'Arquivado com sucesso.',
      body: 'Corpo',
      detail: 'Mensagem',
      inbox: 'Caixa de Entrada',
      list: 'Mensagens',
      new: 'Nova mensagem',
      new_message: 'Nova mensagem',
      new_message_success: 'Mensagem enviada com sucesso',
      new_response: 'Nova resposta',
      no_items_selected: 'Nenhum item selecionado',
      notifications: 'Notificações',
      reply_message: 'Responder',
      response_success: 'Resposta adicionada com sucesso',
      seen: 'Visualizações',
      select_an_item: 'Selecione um item para ler',
      subject: 'Assunto',
      type_a_message: 'Digite uma mensagem',
      unarchivedSuccess: 'Desarquivado com sucesso.',
    },
    nps: {
      _likedTitle: 'O que você mais gostou?',
      _suggestionsTitle: 'Sugestões para melhorias:',
      are_you_still_evaluating: 'Você ainda está avaliando?',
      closing_session_in_seconds: 'Encerrando sessão em {0}...',
      listBadFeedbacks: {
        confusing_system_to_use: 'Sistema confuso de utilizar',
        difficulty_hearing_seeing_the_doctor: 'Dificuldade em ouvir/ver o médico',
        doctors_lack_of_patience: 'Falta de paciência do médico',
        lack_of_doctor_education: 'Falta de educação do médico',
        problem_with_the_system: 'Problema com o sistema',
        the_doctor_didnt_listen_see_me: 'Médico não me escutava/enxergava',
        waiting_time: 'Tempo de espera',
      },
      listGoodFeedbacks: {
        audio_and_video_quality: 'Qualidade do áudio e vídeo',
        doctors_education: 'Educação do médico',
        doctors_patience: 'Paciência do médico',
        easy_to_use_the_system: 'Fácil uso do sistema',
        waiting_time: 'Tempo de espera',
      },
      message: 'Em uma escala de 0 a 10, o quanto você indicaria a teleconsulta para um amigo ou familiar?',
      message_logout: 'Tem certeza de que deseja sair? Sua avaliação não foi enviada.',
      your_review_has_been_successfully_sent: 'Sua avaliação foi enviada com sucesso!',
    },
    pageNotFound: 'Página não encontrada',
    patient: {
      choose_picture: 'Escolher foto',
      edit: 'Editar Perfil',
      ehr: 'Prontuário Eletrônico',
      remove_picture: 'Remover foto',
    },
    person: {
      schedule: 'Agendar Consulta',
    },
    personHome: {
      benefit_club: 'Clube de Benefícios',
    },
    plan: {
      detail: 'Plano',
      edit: 'Editar plano',
      list: 'Planos',
      new: 'Novo plano',
    },
    professional: {
      detail: 'Detalhes do Profissional',
      edit: 'Editar Profissional',
      list: 'Profissional',
      new: 'Novo Profissional',
    },
    request: {
      description: 'Preencha este formulário para abrir sua solicitação',
      stepText1: 'Selecione a especialidade desejada para solicitar o agendamento',
      stepText2: 'Preencha seu número de celular',
      stepText3: 'Se precisar, preencha o campo Observação com algum detalhe adicional',
      stepText4: 'Clique em SOLICITAR e aguarde nosso retorno com as datas disponíveis para agendamento',
      title: 'Solicitar Agendamento',
    },
    requestReturn: {
      description: 'Dados da primeira consulta',
      stepText1: 'Revise os dados da consulta de retorno solicitada',
      stepText2:
        'Preencha seu número de celular e se precisar, preencha o campo Observação com algum detalhe adicional',
      stepText3: 'Clique em SOLICITAR e aguarde nosso retorno com as datas disponíveis para agendamento',
      title: 'Solicitar retorno',
    },
    schedule: {
      action: 'Ação',
      allDay: 'Dia Inteiro',
      allows_patient_to_schedule: 'Especificar prazo para disponibilizar agendamento',
      at: 'às',
      attachExams: 'Caso queira anexar exames, clique no botão enviar e selecione o arquivo desejado.',
      attachFiles: 'Exames Adicionados',
      blocked: 'Bloqueio de Agenda',
      cancel: 'Cancelar',
      click_here_for_attendance: 'Clique aqui para atendimento agora',
      completeFinish: 'Clique no botão finalizar para concluir',
      date: 'Data',
      dateEnd: 'Data Fim',
      dateSchedule: 'Data do agendamento',
      dateStart: 'Data Início',
      day: 'Dia',
      days: {
        0: 'Domingo',
        1: 'Segunda',
        2: 'Terça',
        3: 'Quarta',
        4: 'Quinta',
        5: 'Sexta',
        6: 'Sábado',
      },
      delete: 'Excluir',
      describeText: {
        confirm: 'Confirme os dados do agendamento e clique em <strong>próximo</strong>',
        final:
          'Consulta agendada com sucesso, clique no botão <strong>finalizar</strong> para ser redirecionado para a home',
        payment: 'Realize o pagamento do seu atendimento',
        reason: 'Descreva brevemente o motivo do seu atendimento e anexe exames',
        selectDayHour:
          'Selecione o <strong>dia</strong> e o <strong>horário</strong> da consulta e clique em <strong>próximo</strong>',
        speciality:
          'Agende sua consulta de forma simples e rápida. <br /> Selecione uma <strong>especialidade</strong> e clique em <strong>próximo</strong>',
      },
      errors: {
        already_exists: 'A data inserida já está cadastrada',
        appointments_conflicts: 'Conflitos de Agendamentos',
        greater_limit_days: 'O intervalo de datas não pode ser maior que 35 dias',
        invalid_date: 'A data inserida é inválida',
        invalid_time: 'A hora inserida é inválida',
        less_current_date: 'Por favor, inserir uma data maior que a atual',
        less_selected_start_date: 'Por favor, inserir uma data maior que a data selecionada no calendário',
        less_start_date: 'Por favor, inserir uma data maior ou igual que a data atual',
        less_start_time: 'Por favor, inserir horário maior que a hora início',
        out_of_schedule: 'O horário selecionado está fora da agenda do profissional',
        registered_appointments: 'Atenção! Há agendamentos marcados para esse horário!',
        time_conflict: 'Conflito de horários!',
        time_default: 'Horário inferior ao tempo de consulta padrão',
        wont_be_canceled: 'Não será cancelado nenhum agendamento. Se desejado, isso deve ser feito manualmente.',
      },
      extra: 'Extraordinários',
      filesSend: 'Aguarde... Arquivo sendo enviado',
      finished: 'Consulta Agendada com sucesso',
      fixed_schedule: 'Agenda Fixa',
      hello: 'Olá',
      hourEnd: 'Hora Fim',
      hourStart: 'Hora Início',
      loadingSchedule: 'Buscando datas e horários disponíveis',
      medical_specialty: 'Especialidade',
      my_availability: 'Minha Disponibilidade',
      new: 'Novo',
      noTimes: 'Nenhum Horário Disponível no dia Selecionado',
      notSchedule: 'Nenhum horário disponível para o mês de {0}. Por favor, consulte outro mês.',
      per: 'Por',
      professional: 'Profissional',
      reason: 'O que você está sentindo?',
      scheduled: 'Horário Marcado',
      selectDay: 'Selecione o dia da consulta',
      selectHourProfessional: 'Selecione o horário do profissional',
      textHeaderForwardedIn: 'Encaminhado em',
      textNotHaveSpecialtySub:
        'É necessário passar no pronto-atendimento e ser avaliado pelo profissional em plantão. <br/> Clique no botão abaixo para ser redirecionado',
      textNotHaveSpecialtyTitle: 'Você não tem encaminhamentos',
      textStepConfirm: 'Agendamento',
      textStepExams: 'Sua consulta foi agendada para o dia {0}',
      textStepExamsText: 'com o especialista em {0}',
      textStepForwardingText1: 'Escolha o encaminhamento',
      textStepReason: 'Por favor, nos informe o motivo da consulta e clique em próximo.',
      textStepSelectDay: 'Escolha o dia e horário',
      textStepSpecialty: 'Agende sua consulta de forma simples e rápida.',
      textStepSpecialtyText1: 'Escolha a especialidade',
      textStepSpecialtyText2: 'Escolha o dia e horário',
      textStepSpecialtyText3: 'Descreva brevemente o motivo da consulta',
      textStepSpecialtyText4: 'Confirme os dados',
      textStepSpecialtyText5: 'Anexe exames (se tiver)',
      textStepSpecialtyText6: 'Você pode anexar imagens ou pdf',
      textStepSpecialtyText7:
        'Para iniciar o agendamento, escolha abaixo a especialidade desejada e clique em próximo.',
      textStepSpecialtyTextPayment: 'Realize o pagamento',
      textStepTitleConfirm: 'Confirmação',
      textStepTitleExams: 'Exames',
      textStepTitleFinal: 'Final',
      textStepTitleForwarding: 'Encaminhamento',
      textStepTitlePayment: 'Pagamento',
      textStepTitleReason: 'Motivo',
      textStepTitleSelectDay: 'Data/Horário',
      textStepTitleSpecialty: 'Especialidade',
      time: 'Horário',
      timeAllowed: 'Permitir Paciente Agendar',
    },
    screening: {
      artificial_intelligence: {
        example: 'Exemplo:',
        instructions: {
          analyzing_complaint: 'Analisando a sua queixa, por favor aguarde!',
          ask_questions: 'Agora, vou te fazer algumas perguntas',
          confirm_said: 'Você disse:',
          describe: 'Descreva brevemente sobre os sintomas que está sentido e há quanto tempo eles começaram.',
          error_navigation: 'Você pode prosseguir para a Fila Virtual',
          example: '"Estou com a garganta inflamada e febre há 2 dias."',
          lets_try_again: 'Vamos tentar novamente',
          more_ask_questions: 'Analisando suas respostas',
          printing_label: 'Estou imprimindo a sua etiqueta',
          speak: 'Clique no microfone e fale brevemente os sintomas que está sentindo e há quanto tempo',
          speak_understand: 'Não consegui compreender, vamos tentar novamente!',
          thanks_subtitle: 'Agora você será encaminhado(a) para a fila de atendimento.',
          thanks_title: 'Agradeço pelas informações',
          type: 'Descreva brevemente os sintomas que está sentindo e há quanto tempo',
          wait: 'Aguarde',
        },
        steps: {
          answer_questions: 'Responda as perguntas e clique em <strong>"enviar"</strong> quando finalizar',
          answer_questions_2: 'Responda as perguntas e clique em "Enviar" quando finalizar',
          confirm_audio: 'Clique no <strong>"microfone"</strong> para iniciar',
          confirm_audio_2: 'Clique em "Enviar" se estiver correto, caso contrário em "Repetir"',
          confirm_text: 'Clique em <strong>"enviar"</strong> quando finalizar',
          intro: 'Clique no botão <strong>"iniciar"</strong> para começar a triagem',
        },
      },
      required: 'Campo obrigatório',
    },
    use_term: {
      not_accept_term: 'Não será possivel acessar o sistema sem aceitar o termos de uso',
    },
    waitingroom: {
      room: 'Sala de Espera',
    },
  },
  periods: {
    '7_days': '7 dias',
    '15_days': '15 dias',
    '30_days': '30 dias',
  },
  testConnectivity: {
    audio: {
      bad: 'Conectividade do áudio ruim',
      fair: 'Conectividade do áudio razoável',
      good: 'Conectividade do áudio boa',
      testing: 'Testando o Áudio',
    },
    connection: {
      failure: 'Falha nos testes de conexão',
      OK: 'Conectado com sucesso em nossos serviços',
      testing: 'Testando Conexão...',
    },
    error: {
      APIConnectivityError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      ConnectivityError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      ConnectToSessionError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      ConnectToSessionNetworkError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      ConnectToSessionSessionIdError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      ConnectToSessionTokenError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      FailedMessagingServerTestError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      FailedToCreateLocalPublisher:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      FailedToObtainMediaDevices:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      helpCamera:
        '- Verifique na barra de endereço do seu navegador se o acesso à câmera esta bloqueado <span class="material-icons" style="vertical-align: bottom;color: #000;">videocam_off</span>, clique no ícone e dê permissão',
      helpCameraConfig:
        '- Verifique nas configurações do seu navegador se a câmera está bloqueada para esse aplicativo e conceda a permissão',
      helpCameraDupliciteUse:
        '- Verifique se a câmera está sendo utilizado por outro aplicativo, feche todos e acesse novamente',
      helpCameraLink:
        '- Para outros casos, <a href="https://suporte.doutoraovivo.com.br/hc/pt-br/articles/360048709214-PERMITIR-ACESSO-A-C%C3%82MERA-NO-NAVEGADOR" target="_blank" rel="noreferrer nofollow">clique aqui</a>',
      IncompleteSessionCredentialsError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      InitPublisherError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      InvalidOnUpdateCallback:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      LoggingServerConnectionError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      MediaDeviceError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      MissingOpenTokInstanceError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      MissingSessionCredentialsError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      MissingSubscriberError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      NetworkTestError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      NoAudioCaptureDevicesError:
        'Não conseguimos acessar seu microfone, precisamos desse acesso para o Teleatendimento.',
      NoVideoCaptureDevicesError: 'Não conseguimos acessar sua câmera, precisamos desse acesso para o Teleatendimento.',
      PublishToSessionError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      PublishToSessionNetworkError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      PublishToSessionNotConnectedError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      PublishToSessionPermissionOrTimeoutError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      QualityTestError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      SubscriberGetStatsError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      SubscribeToSessionError:
        'Não conseguimos acessar sua câmera e/ou microfone, precisamos desse acesso para o Teleatendimento.',
      title: 'Possíveis Correções:',
      UnsupportedBrowser: 'Navegador de Internet não suportado',
    },
    failureDetails: 'Ver detalhes',
    video: {
      bad: 'Conectividade do video ruim',
      fair: 'Conectividade do video razoável',
      good: 'Conectividade do video boa',
      testing: 'Testando o Vídeo',
    },
  },
  title: {
    appointment: {
      cid10: 'Informe o CID10',
      description: 'Descrição do Atendimento',
      doctor: 'Profissionais',
      doctor_id: 'Profissional',
      end_date: 'Data Término',
      end_date_time: 'Data / Hora de término do Atendimento',
      end_time: 'Hora Término',
      medical_assistent: 'Profissional Assistente',
      notes: 'Notas do Atendimento',
      patient_id: 'Paciente',
      role: 'Tipo Participante',
      start_date: 'Data Início',
      start_date_time: 'Data / Hora de início do Atendimento',
      start_time: 'Hora Início',
      status_appointment: 'Status de Agendamento',
      title: 'Título do Atendimento',
      type: 'Tipo de Atendimento',
      upload_image: 'Upload de imagem',
      upshot: 'Informe o desfecho do atendimento',
    },
    company: {
      birth_date: 'Data de Nascimento',
      business_contact: 'Contato de négocio',
      cell_phone: 'Celular',
      city: 'Cidade',
      cnpj: 'CNPJ',
      comission_type: 'Tipo de comissão',
      comission_value: 'Valor de comissão',
      company_id: 'Empresa',
      complement: 'Complemento',
      cpf: 'CPF',
      edit: 'Editar',
      expiration_date: 'Data de Validade',
      gender: 'Sexo',
      mail: 'E-mail',
      name: 'Nome Fantasia',
      neighborhood: 'Bairro',
      number: 'Número',
      parent_id: 'Pessoa responsável financeiramente pelo paciente',
      photo: 'Foto',
      plan_id: 'ID plano',
      region: 'Unidade Federal',
      representative_id: 'ID representante',
      social_name: 'Razão Social',
      status: 'Status',
      street: 'Rua, Avenida...',
      telephone: 'Telefone',
      zip_code: 'CEP',
    },
    doctor: {
      birth_date: 'Data de Nascimento',
      cell_phone: 'Celular',
      city: 'Cidade',
      company_id: 'Empresa',
      complement: 'Complemento',
      cpf: 'CPF',
      crm: 'CRM',
      crmState: 'Estado do Conselho Regional de Medicina',
      Filtro: 'Filtro',
      gender: 'Sexo',
      id: 'Identificador',
      mail: 'E-mail',
      medical_specialty: 'Especialidade Médica',
      name: 'Nome',
      neighborhood: 'Bairro',
      number: 'Número',
      picture: 'Foto',
      region: 'Unidade Federal',
      status: 'Status',
      street: 'Rua, Avenida...',
      telephone: 'Telefone',
      zip_code: 'CEP',
    },
    patient: {
      address: 'Endereço',
      birth_date: 'Data de Nascimento',
      cell_phone: 'Celular',
      city: 'Cidade',
      company_id: 'Empresa',
      complement: 'Complemento',
      country: 'País',
      cpf: 'CPF',
      gender: 'Sexo',
      mail: 'E-mail',
      name: 'Nome',
      neighborhood: 'Bairro',
      number: 'Numero',
      parent_id: 'Pessoa responsável financeiramente pelo paciente',
      profession: 'Profissão do paciente',
      region: 'Região',
      status: 'Status',
      street: 'Rua Avenida...',
      telephone: 'Telefone',
      zip_code: 'CEP',
    },
    plan: {
      additional_minute_recorded_allowed: 'Indica se esse plano permite minuto adicional para gravação',
      additional_minute_value: 'Valor do minuto adicional de consulta do plano',
      additional_minutes_allowed: 'Indica se esse plano permite minuto adicional para consulta',
      additional_recorded_minute_value: 'Valor do minuto adicional de gravação do plano',
      attendance_average_minutes: 'Quantidade média de minutos de consulta para apuração de contrato',
      attendance_average_minutes_recorded: 'Quantidade média de minutos de gravação para apuração de contrato',
      attendance_value: 'Valor da Consulta',
      bill_type: 'Tipo do plano',
      commission_type: 'Tipo da Comissão',
      commission_value: 'Valor da Comissão',
      description: 'Nome do Plano',
      duration_days: 'Indica a quantidade de dias de duração do plano',
      initial_attendance_quantity: 'Quantidade inicial de consultas para essa faixa',
      max_additional_minute: 'Quantidade máxima de minutos adicionais para consulta',
      max_additional_recorded_minute: 'Quantidade máxima de minutos adicionais para gravação',
      max_attendance_minutes: 'Indica a duração máxima de minutos da consulta',
      minutes_quantity: 'Quantidade de minutos de consulta do plano',
      minutes_value: 'Valor do minuto de consulta do plano',
      name: 'Nome do Plano',
      recorded_minute_value: 'Valor do minuto de gravação do plano',
      recorded_minutes_quantity: 'Quantidade de minutos de gravação do plano',
    },
    professional: {
      birth_date: 'Data de Nascimento',
      cell_phone: 'Celular',
      city: 'Cidade',
      company_id: 'Empresa',
      complement: 'Complemento',
      cpf: 'CPF',
      crm: 'CRM',
      Filter: 'Filtro',
      gender: 'Sexo',
      id: 'Identificador',
      mail: 'E-mail',
      medical_specialty: 'Especialidade Médica',
      name: 'Nome',
      neighborhood: 'Bairro',
      number: 'Número',
      photo: 'Foto',
      region: 'Unidade Federal',
      role: 'Cargo do Profisional',
      status: 'Status',
      street: 'Rua, Avenida...',
      telephone: 'Telefone',
      zip_code: 'CEP',
    },
    request: {
      contact: 'Número de contato',
      observation: 'Observação',
      specialty: 'Especialidade',
    },
    settings: {
      allows_patient_to_schedule_time_before:
        'Tempo permitido para o paciente realizar agendamento antes do horário disponíbilizado',
      appointment_duration: 'Tempo médio da consulta em minutos',
      period: 'Período',
    },
  },
  value: {
    active: 'Ativo',
    inactive: 'Inativo',
    no: 'Não',
    yes: 'Sim',
  },
};

export default ptBR;
